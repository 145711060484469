/**
 * Módulo do store responsável por buscar
 * e guardar os tipos de parâmetros utilizados na aplicação.
 */
import axios from "axios"
import _ from "lodash"

import { DocumentMasks, DocumentTypeValues } from "@/consts"
import http from "@/http"
import i18n from "@/i18n"
import { getDocumentTypesList } from "@/utils/parameters"
import { changeChunkDeep } from "@/utils/vuex"

const MemoryCache = {
  States: {},
  Cities: {},
}

/**
 *
 */
const state = {
  countries: [],
  states: [],
  cities: [],
  jobs: [],
  purposes: [],
  onboarding: false,
  personalizationInfo: {},
  transportTypes: [],
  documentTypes: [
    { text: i18n.tc("parameters.id"), value: DocumentTypeValues.ID },
    { text: i18n.tc("parameters.cie"), value: DocumentTypeValues.CIE },
    {
      text: i18n.tc("parameters.cpf"),
      value: DocumentTypeValues.CPF,
      mask: DocumentMasks.CPF,
    },
    { text: i18n.tc("parameters.rut"), value: DocumentTypeValues.RUT },
    {
      text: i18n.tc("parameters.passport"),
      value: DocumentTypeValues.PASSPORT,
    },
    { text: i18n.tc("parameters.dni"), value: DocumentTypeValues.DNI },
    {
      text: i18n.tc("parameters.birth_certificate"),
      value: DocumentTypeValues.BIRTH_CERTIFICATE,
    },
  ],
  mainDocument: {
    defaultGuests: {},
    foreignersGuests: {},
    minorsGuests: {},
  },
  additionalDocument: {
    types: {
      defaultGuests: {},
      foreignersGuests: {},
      minorsGuests: {},
    },
    required: "",
  },
  searchDocumentTypes: {
    cpf: { disable: false },
    passport: { disable: false },
    dni: { disable: false },
  },
  cepSearch: {
    address: "",
    city: "",
    uf: "",
  },
  statesOptions: {
    residence: [],
    origin: [],
    destiny: [],
    addressForgetCep: [],
    billing: [],
  },
  citiesOptions: {
    residence: [],
    origin: [],
    destiny: [],
    billing: [],
  },
  addressResults: [],
}

/**
 *
 */
const mutations = {
  setPurposes(state, purposes) {
    state.purposes = purposes
  },
  setOnboarding(state, hasOnboarding) {
    state.onboarding = hasOnboarding
  },
  setJobs(state, jobs) {
    state.jobs = jobs
  },
  setTransportTypes(state, transportTypes) {
    state.transportTypes = transportTypes
  },
  setDocumentTypes(state, documentTypes) {
    state.mainDocument = documentTypes
  },
  setAdditionalDocument(state, additionalDocument) {
    state.additionalDocument = additionalDocument
  },
  setCountries(state, countries) {
    state.countries = countries
  },
  setStatesOptions(state, { states, section }) {
    state.statesOptions[section] = states
  },
  setCitiesOptions(state, { cities, section }) {
    state.citiesOptions[section] = cities
  },
  setPersonalizationInfo(state, personalizationInfo) {
    state.personalizationInfo = personalizationInfo
    state.personalizationInfo.checkinInfo.image = personalizationInfo
      .checkinInfo.image
      ? process.env.VUE_APP_ASSETS_URL + personalizationInfo.checkinInfo.image
      : ""
  },
  setCepSearch: changeChunkDeep("cepSearch"),
  setAddressResults(state, addressResults) {
    state.addressResults = addressResults
  },
}

/**
 *
 */
const actions = {
  setCountries({ commit }, countries = []) {
    commit("setCountries", countries)
  },

  /**
   *
   */
  async getCountries({ commit }) {
    const resp = await http.get("/countries")

    commit("setCountries", resp.data)
  },

  /**
   *
   */
  async getStates({ commit }, { countryCode, section }) {
    if (Array.isArray(MemoryCache.States[countryCode])) {
      commit("setStatesOptions", {
        section,
        states: MemoryCache.States[countryCode],
      })
      return
    }
    const resp = await http.get(`/countries/${countryCode}/states`)

    commit("setStatesOptions", { section, states: resp.data })
    MemoryCache.States[countryCode] = resp.data
  },

  /**
   *
   */
  async getCities({ commit }, { section, stateId }) {
    if (Array.isArray(MemoryCache.Cities[stateId])) {
      commit("setCitiesOptions", {
        section,
        cities: MemoryCache.Cities[stateId],
      })
      return
    }
    const resp = await http.get(`/states/${stateId}/cities`)

    commit("setCitiesOptions", { section, cities: resp.data })
    MemoryCache.Cities[stateId] = resp.data
  },

  /**
   * Carrega os estados do Brasil por padrão em todas as seções
   */
  async loadStates({ commit }) {
    const resp = await http.get(`/countries/${"BR"}/states`)

    commit("setStatesOptions", { section: "residence", states: resp.data })
    commit("setStatesOptions", { section: "origin", states: resp.data })
    commit("setStatesOptions", { section: "destiny", states: resp.data })
    MemoryCache.States["br"] = resp.data
  },

  async getCEPFromAddressUsingViaCEP({ state, commit }) {
    const resp = await axios
      .get(
        `https://viacep.com.br/ws/${state.cepSearch.uf}/${state.cepSearch.city}/${state.cepSearch.address}/json`
      )
      .catch(function (error) {
        if (error.response) {
          throw new Error("syntaxe error")
        }
      })
    if (resp.data.length < 1) {
      throw new Error("Not found")
    }
    commit("setAddressResults", resp.data)
  },
}

/**
 *
 */
const getters = {
  defaultDocumentTypes(state) {
    const types = state.mainDocument?.defaultGuests?.types
    return getDocumentTypesList(types)
  },

  foreignDocumentTypes(state) {
    const types = state.mainDocument?.foreignersGuests?.types
    return getDocumentTypesList(types)
  },

  minorsDocumentTypes(state) {
    const types = state.mainDocument?.minorsGuests?.types
    return getDocumentTypesList(types)
  },
  defaultAdditionalTypes(state) {
    const types = state.additionalDocument?.defaultGuests?.types
    return getDocumentTypesList(types)
  },

  foreignAdditionalTypes(state) {
    const types = state.additionalDocument?.foreignersGuests?.types
    return getDocumentTypesList(types)
  },

  minorsAdditionalTypes(state) {
    const types = state.additionalDocument?.minorsGuests?.types
    return getDocumentTypesList(types)
  },

  getDocumentosScanEnableds(state) {
    const getScannerEnabled = (path) =>
      _.get(state, `${path}.enableScanner`, false)

    return {
      mainDocument: {
        minorsGuests: getScannerEnabled("mainDocument.minorsGuests"),
        foreignersGuests: getScannerEnabled("mainDocument.foreignersGuests"),
      },
      additionalDocument: {
        minorsGuests: getScannerEnabled("additionalDocument.minorsGuests"),
        foreignersGuests: getScannerEnabled(
          "additionalDocument.foreignersGuests"
        ),
      },
    }
  },

  searchDocumentTypes() {
    return [
      {
        text: i18n.tc("parameters.cpf"),
        mask: DocumentMasks.CPF,
        value: DocumentTypeValues.CPF,
      },
      {
        text: i18n.tc("parameters.passport"),
        value: DocumentTypeValues.PASSPORT,
      },
      { text: i18n.tc("parameters.dni"), value: DocumentTypeValues.CIE },
    ]
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

import store from "@/store"

import {
  isInitialConfigLoaded,
  setInitalConfigLoaded,
} from "./guardsSharedState"

/**
 * Guarda de rota responsável em redirecionar para
 * o lugar correto caso seja feita a entrada direta na aplicação
 */
export async function isHashEntry(to, from, next) {
  if (isInitialConfigLoaded) {
    next()
    return
  }

  const isHashUrl = to.name === "validate-hash"
  const isFirstEntry = from.name === null

  if (isHashUrl && isFirstEntry) {
    const contactInfo = JSON.parse(localStorage.getItem("contactInfo"))
    const guestId = contactInfo?.guestId
    const contactId = contactInfo?.contactId

    await store.dispatch("getInitialConfig", { guestId })
    setInitalConfigLoaded(true)

    const hash = location.pathname.split("/")[3]

    try {
      const adicionais = store.state.company.stepsConfig.find(
        (step) => step.id === "dados-adicionais"
      )
      store.commit(
        "guest/loadAdditionalData",
        adicionais.fields[0].additionalData
      )
      await store.dispatch("guest/getGuestByHash", {
        guestId,
        contactId,
        hash: hash,
      })
      await store.dispatch(
        "search/searchReservationByGuestId",
        guestId ?? store.state.guest.auth.clientId
      )
      store.dispatch("flow/guest/start", {
        isMainGuest: store.state.guest.guest.masterGuest,
        isChild: store.state.guest.guest.isChild,
      })
      store.commit("guest/auth/setQualified", true)
      next({ name: "personal-info" })
    } catch (e) {
      console.log(e)
      localStorage.setItem("expiredPin", true)
      window.location = "/"
    }
  } else {
    next()
  }
}

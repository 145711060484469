/* eslint-disable max-lines */
import find from "lodash.find"
import get from "lodash.get"
import omit from "lodash.omit"
import Vue from "vue"

import http, { httpViaCep } from "@/http"
import { Guest, TermTypes } from "@/models"
import { getMasterGuestFromStorage, saveMasterGuestStorage } from "@/storage"
import { formatGuestToSend, readGuestFromServer } from "@/utils/guest"
import { postDocumentWithPresignedURL } from "@/utils/upload"
import { changeChunkDeep } from "@/utils/vuex"

// Modules
import auth from "./guest/auth"
import documents from "./guest/documents"
import nps from "./guest/nps"

const LAST_GUEST_KEY = "zooxpass:lastguest"

/**
 * Responsável por guardar as informações do guest
 * carregado
 */
const state = {
  isLoaded: false,
  hasWebauthn: false,
  isAdditionalDocument: false,
  expiredPin: false,
  utilizeMasterGuestData: false,
  guest: Guest(),
  hasOrder: false,
  signature: "",
}

/**
 *
 */
const mutations = {
  changeGuest: changeChunkDeep("guest"),
  changeSignature(state, signature) {
    state.signature = signature
  },
  resetGuest(state) {
    state.isLoaded = false
    state.guest = Guest()
  },
  setAdditionalDocument(state, additional) {
    state.isAdditionalDocument = additional
  },

  setHasOrder(state, hasOrder) {
    state.hasOrder = hasOrder
  },
  loadAdditionalData(state, additionalData = []) {
    for (let field of additionalData) {
      Vue.set(state.guest.additionalData, field.id, field.default)
    }
  },
  resetZipCode(state) {
    state.guest.zipCode = ""
  },
  setUseMasterGuestData(state, value) {
    state.utilizeMasterGuestData = value
  },
  setIsLoaded(state, isLoaded) {
    state.isLoaded = isLoaded
  },
  saveLastGuestFilled(state, reservationNumber) {
    localStorage.setItem(
      `${LAST_GUEST_KEY}:${reservationNumber}`,
      JSON.stringify({
        guestId: state.guest.id,
      })
    )
  },
  assertHasTermIds(state, { use, privacy }) {
    const guestUse = find(state.guest.terms, { type: TermTypes.Use })
    if (guestUse != null) {
      guestUse.id = use.id
    }

    const guestPrivacy = find(state.guest.terms, { type: TermTypes.Privacy })
    if (guestPrivacy != null) {
      guestPrivacy.id = privacy.id
    }
  },
}

/**
 *
 */
const actions = {
  async sendImagetoRecognition() {
    try {
      const sendImage = await http.post("/clients/index-biometrics", {
        key: state.guest.photoUrl,
        personId: state.guest.personId,
      })

      return sendImage
    } catch (e) {
      throw new Error("Erro ao registrar imagem como biometria")
    }
  },

  async sendTermsToGuest({ rootState }, term) {
    try {
      const sendTermToEmail = await http.post("/terms/sendToGuest", {
        termId:
          term === "use"
            ? rootState.company.terms.use.id
            : rootState.company.terms.privacy.id,
        name: state.guest.name,
        email: state.guest.email,
      })

      return sendTermToEmail
    } catch (e) {
      throw new Error("Erro ao enviar termos para o email")
    }
  },

  async getOrderByReservationNumber({ commit, rootState }) {
    try {
      const companyId = rootState.company.companyId
      const reservationNumber =
        rootState.reservation.reservation.reservationNumber

      const { data: order } = await http.get(
        `payment/order/reservation/${companyId}/${reservationNumber}`
      )

      if (order?.chargesAtCheckin?.length > 0) {
        commit("setHasOrder", true)
        commit("warranty/payment/changeOrder", order, {
          root: true,
        })
      } else {
        commit("setHasOrder", false)
        commit(
          "warranty/payment/changeOrder",
          {
            id: "",
            status: "",
            closed: false,
            chargesAtCheckin: [],
          },
          {
            root: true,
          }
        )
      }
    } catch (e) {
      console.error(e)
    }
  },

  /**
   *
   */
  async tryAuthOrEditGuest({ dispatch, commit, state, rootGetters }, guest) {
    commit("resetGuest")
    commit("changeGuest", {
      id: guest.id,
      masterGuest: guest.masterGuest,
      name: guest.name,
      lastName: guest.lastName,
    })
    commit(
      "loadAdditionalData",
      rootGetters["company/additionalDataConfig"].additionalData?.length > 0
        ? rootGetters["company/additionalDataConfig"].additionalData
        : []
    )

    try {
      await dispatch("auth/tryAuthenticate", guest)
      if (state.auth.token) {
        await dispatch("getGuest")
      }
    } catch {
      if (guest?.masterGuest === true)
        await dispatch("getOrderByReservationNumber")
    }
  },

  /**
   * Busca as informações do guest da ficha
   */
  async getGuest({ state, commit, dispatch }) {
    const resp = await http.get(`/clients/${state.guest.id}`, {
      headers: {
        Authorization: `Bearer ${state.auth.token}`,
      },
    })
    const guest = find(resp.data.guests, { id: state.guest.id })
    const order = resp.data?.order

    commit(
      "changeGuest",
      readGuestFromServer(guest, { additionalData: state.guest.additionalData })
    )
    commit("setIsLoaded", true)
    dispatch("loadParameters", { fillPerson: true })

    if (order?.chargesAtCheckin?.length > 0) {
      commit("setHasOrder", true)

      commit("warranty/payment/changeOrder", order, {
        root: true,
      })
    } else {
      //reset orders

      commit("setHasOrder", false)
      commit(
        "warranty/payment/changeOrder",
        {
          id: "",
          status: "",
          closed: false,
          chargesAtCheckin: [],
        },
        {
          root: true,
        }
      )
    }
  },

  /**
   * Carrega os parâmetros necessários quando o person é carregado
   */
  async loadParameters({ state, dispatch }, { fillPerson = false } = {}) {
    if (state.guest !== "") {
      dispatch("tryFillByZipCode", { fillPerson })
    }

    if (state.guest.arrivingFromCountryId === "BR") {
      dispatch(
        "parameters/getStates",
        { countryCode: "br", section: "origin" },
        { root: true }
      )
      dispatch(
        "parameters/getCities",
        { stateId: state.guest.arrivingFromStateId, section: "origin" },
        { root: true }
      )
    }

    if (state.guest.nextDestinationCountryId === "BR") {
      dispatch(
        "parameters/getStates",
        { countryCode: "br", section: "destiny" },
        { root: true }
      )
      dispatch(
        "parameters/getCities",
        { stateId: state.guest.nextDestinationStateId, section: "destiny" },
        { root: true }
      )
    }
  },

  /**
   * Preenche os dados do de residência com base no CEP informado
   */
  async tryFillByZipCode({ commit, dispatch, state, rootState }, payload) {
    const fillPerson = payload?.fillPerson ?? false

    const zipData = await httpViaCep.get(`ws/${state.guest.zipCode}/json`)

    const resp = await http.post(`/zip/getdata`, zipData.data)

    if (resp.data.length) {
      const region = resp.data[0]

      await Promise.all([
        dispatch(
          "parameters/getStates",
          { countryCode: "br", section: "residence" },
          { root: true }
        ),
        dispatch(
          "parameters/getCities",
          { stateId: region.stateId, section: "residence" },
          { root: true }
        ),
      ])

      const states = rootState.parameters.statesOptions.residence
      const stateName = get(find(states, { id: region.stateId }), "name") ?? ""

      const cities = rootState.parameters.citiesOptions.residence
      const cityName = get(find(cities, { id: region.cityId }), "name") ?? ""

      commit("changeGuest", {
        stateId: region.stateId || "",
        stateName,
        cityId: region.cityId || "",
        cityName,
        neighborhood: region.neighborhood || "",
        address: region.route || "",
        addressNumber:
          fillPerson == true ? state.guest?.addressNumber ?? "" : "",
        addressComplement:
          fillPerson == true ? state.guest?.addressComplement ?? "" : "",
      })
    }
  },

  /**
   * Salva os dados de origem ( Email ou SMS )
   */
  async saveTrackData(_, params) {
    await http.post(
      `/track/${params.guestId}`,
      {},
      {
        headers: {
          ["x-origin"]: params.origin,
        },
      }
    )
  },

  /**
   * Salva o guest
   */
  async saveGuest({ state, commit, rootState }) {
    commit("assertHasTermIds", rootState.company.terms)

    const guest = formatGuestToSend(state.guest, {
      countries: rootState.parameters.countries,
      jobs: rootState.parameters.jobs,
    })
    const resp = await http.put(`/clients/${guest.id}`, guest, {
      headers: { Authorization: `Bearer ${state.auth.token}` },
    })
    const guestServer = find(resp.data.guests, { id: guest.id })

    commit(
      "changeGuest",
      readGuestFromServer(guestServer, {
        additionalData: state.guest.additionalData,
      })
    )

    if (guest.masterGuest) {
      const reservation = rootState.reservation.reservation

      saveMasterGuestStorage(reservation.id, state.guest)
    }
  },

  /**
   * Salva o horário do início, ou fim do preenchimento
   */
  async saveFillData(_, params) {
    const { id, formOrigin } = params
    await http.post(`/track/${id}/filling-data`, { formOrigin })
  },

  /**
   * Salva a FNRH no final do fluxo
   */
  async saveFNRH({ state, dispatch, rootState, commit }) {
    await dispatch("saveFillData", { id: state.guest.id })

    await http.post("/clients/finalize-register", {
      companyId: rootState.company.companyId,
      reservationId: rootState.reservation.reservation.id,
      clientId: state.guest.id,
      immediatelyExport: true,
    })

    commit(
      "saveLastGuestFilled",
      rootState.reservation.reservation.reservationNumber
    )
    localStorage.removeItem("contactInfo")
  },

  /**
   * Envia a assinatura e verifica adiciona a assinatura no guest
   */
  async sendSignature({ state, commit }) {
    const { key } = await postDocumentWithPresignedURL({
      path: "signature",
      src: state.signature,
    })

    const id = key.split("/").pop()

    commit("changeGuest", { signatureUrl: key, signatureId: id })
  },

  /**
   * Salva a imagem do usuário para reconhecimento facial
   */
  async saveFaceImage({ state, commit, rootState }) {
    const { key } = await postDocumentWithPresignedURL({
      path: `${rootState.company.companyId}/${state.guest.id}/imagem`,
      src: state.guest.photoUrlMemory,
      ext: state.guest.photoId,
    })

    commit("changeGuest", { photoUrl: `${key}` })
  },

  /**
   * Salva a parte de trás do documento de identificação
   */
  async saveFrontDocumentsImage({ state, commit, rootState }) {
    if (state.isAdditionalDocument) {
      const { key } = await postDocumentWithPresignedURL({
        path: `${rootState.company.companyId}/${state.guest.id}/imagem`,
        src: state.guest.additionalDocument.frontImageMemory,
        ext: state.guest.additionalDocument.frontImage,
      })

      commit("changeGuest", {
        "additionalDocument.front": `${key}`,
      })
    } else {
      const { key } = await postDocumentWithPresignedURL({
        path: `${rootState.company.companyId}/${state.guest.id}/imagem`,
        src: state.guest.documents.frontImageMemory,
        ext: state.guest.documents.frontImage,
      })

      commit("changeGuest", { "documents.front": `${key}` })
    }
  },

  /**
   * Salva a parte de trás do documento de identificação
   */
  async saveBackDocumentsImage({ state, commit, rootState }) {
    if (state.isAdditionalDocument) {
      const { key } = await postDocumentWithPresignedURL({
        path: `${rootState.company.companyId}/${state.guest.id}/imagem`,
        src: state.guest.additionalDocument.backImageMemory,
        ext: state.guest.additionalDocument.backImage,
      })

      commit("changeGuest", {
        "additionalDocument.back": `${key}`,
      })
    } else {
      const { key } = await postDocumentWithPresignedURL({
        path: `${rootState.company.companyId}/${state.guest.id}/imagem`,
        src: state.guest.documents.backImageMemory,
        ext: state.guest.documents.backImage,
      })

      commit("changeGuest", { "documents.back": `${key}` })
    }
  },

  changeUseMasterGuestData({ commit, dispatch }, value) {
    commit("setUseMasterGuestData", value)

    if (value) dispatch("copyDataFromMasterGuest")
    else dispatch("cleanupDataCopiedFromMaster")
  },

  /**
   *
   */
  copyDataFromMasterGuest({ commit, dispatch, rootState }) {
    const reservation = rootState.reservation.reservation
    const masterGuest = getMasterGuestFromStorage(reservation.id)

    if (masterGuest) {
      const masterGuestData = omit(Object.assign({}, masterGuest), [
        "birthdate",
        "birthdateDay",
        "birthdateMonth",
        "birthdateYear",
        "documents",
        "additionalDocument",
        "documentNumber",
        "documentTypeId",
        "email",
        "gender",
        "id",
        "informSocialName",
        "jobId",
        "jobName",
        "lastName",
        "masterGuest",
        "mobile",
        "mobilePhoneNumber",
        "name",
        "personId",
        "photoId",
        "phoneNumber",
        "photoUrl",
        "photoUrlMemory",
        "signatureId",
        "signatureUrl",
        "socialName",
        "upload",
        "isChild",
      ])

      commit("changeGuest", masterGuestData)
      dispatch("loadParameters", { fillPerson: true })
    }
  },

  cleanupDataCopiedFromMaster({ commit }) {
    const copiedFieldsWithDefault = omit(Guest(), [
      "birthdate",
      "birthdateDay",
      "birthdateMonth",
      "birthdateYear",
      "documents",
      "additionalDocument",
      "documentNumber",
      "documentTypeId",
      "email",
      "gender",
      "id",
      "informSocialName",
      "jobId",
      "jobName",
      "lastName",
      "masterGuest",
      "mobile",
      "mobilePhoneNumber",
      "name",
      "personId",
      "photoId",
      "phoneNumber",
      "photoUrl",
      "photoUrlMemory",
      "signatureId",
      "signatureUrl",
      "socialName",
      "upload",
      "isChild",
    ])

    commit("changeGuest", copiedFieldsWithDefault)
  },

  async getGuestByHash(
    { commit, dispatch, state },
    { contactId, guestId, hash }
  ) {
    await dispatch("auth/verifyPinByHash", { contactId, guestId, hash })

    const hardenedGuestId = guestId ?? state.auth.clientId

    commit("changeGuest", { id: hardenedGuestId })
    await dispatch("getGuest")
  },
}

/**
 *
 */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  modules: {
    auth,
    documents,
    nps,
  },
}

/* eslint-disable max-statements */
import find from "lodash.find"

import http from "@/http"

let currentCompany = process.env.VUE_APP_COMPANY
const isLocalhost = window.location.hostname.startsWith("localhost")
const isLocalNetwork = window.location.hostname.startsWith("192.168.")

if (!isLocalhost && !isLocalNetwork) {
  currentCompany = window.location.hostname.split(".")[0]
}

export const actions = {
  async getInitialConfig(
    { commit, dispatch },
    { guestId = "", reservationId = "", origin = null, lang = "" } = {}
  ) {
    const resp = await http.get(`/companies/${currentCompany || "zooxsmart"}`)

    // Caso não seguir a estrutura de configuração correta
    // deve redirecionar para o portal antigo
    if (!resp.data?.rules?.enableNewPortal) {
      if (isLocalhost) {
        console.error(
          "Portal novo desabilitado. Habilite o portal pelo admin para ver pelo localhost"
        )
        return
      }

      const legacyRoute =
        "/legacy" + window.location.pathname + window.location.search

      window.location = legacyRoute

      throw { response: { status: 302 } }
    }

    const welcomeStep = find(resp.data.steps, { id: "welcome" })

    // Layout Setup
    if (lang) {
      commit("layout/changeLang", lang)
    }
    dispatch("layout/setup", {
      defaultLang: resp.data.languages?.default,
      theme: resp.data.theme,
    })

    // Company Setup
    commit("company/setCompany", {
      name: resp.data.name,
      companyId: resp.data.companyId,
      groupId: resp.data.groupId,
      welcome: welcomeStep?.rules || {},
      rules: resp.data.rules,
    })
    commit("company/setHotel", resp.data.hotel)
    commit("company/setStepsConfig", resp.data.steps)
    dispatch("company/getTermsOfUse", lang)
    dispatch("company/getTermsOfPrivacy", lang)

    // Parameters Setup
    commit("parameters/setTransportTypes", resp.data.parameters.transportTypes)
    commit(
      "parameters/setDocumentTypes",
      resp.data.parameters.documentTypes.mainDocument
    )
    commit(
      "parameters/setAdditionalDocument",
      resp.data.parameters.documentTypes.additionalDocument
    )
    commit("parameters/setJobs", resp.data.parameters.jobs)
    commit("parameters/setPurposes", resp.data.parameters.purposes)
    commit("parameters/setCountries", resp.data.parameters.countries)
    commit("parameters/setPersonalizationInfo", resp.data.personalization)
    dispatch("parameters/loadStates")

    // Search setup
    commit("search/setSearchTypes", resp.data.parameters?.searchTypes)

    dispatch("flow/reservation/start", {
      hasReservationId: !!guestId,
    })

    if (origin != null && guestId != null)
      dispatch("guest/saveTrackData", { origin, guestId })

    let isReservationLoaded = false
    if (guestId) {
      isReservationLoaded = await tryLoadReservation({
        dispatch,
        guestId,
        reservationId,
      })
    } else if (reservationId) {
      isReservationLoaded = await tryLoadByReservationId({
        dispatch,
        reservationId,
      })
    }

    dispatch("flow/reservation/start", {
      hasReservationId: isReservationLoaded,
    })
  },
}

async function tryLoadReservation({ dispatch, guestId, reservationId }) {
  try {
    await dispatch("search/searchReservationByGuestId", guestId)
    return true
  } catch (e) {
    if (e.response.status === 404) {
      return tryLoadByReservationId({ dispatch, reservationId })
    }
    return false
  }
}

async function tryLoadByReservationId({ dispatch, reservationId }) {
  try {
    await dispatch("search/searchReservationById", reservationId)
    return true
  } catch (e) {
    return false
  }
}
